<template>
  <div>
    <div class="grid gap-x-3 gap-y-5 grid-cols-2">
      <!-- Doctor name -->
      <div class="flex flex-col">
        <div
          class="flex flex-row gap-x-1 items-center font-EffraR"
          style="color: #6d6d6d"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              stroke="#767676"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13.333 14v-1.333c0-.708-.28-1.386-.78-1.886S11.373 10 10.666 10H5.333c-.707 0-1.385.281-1.885.781s-.781 1.178-.781 1.886V14M8 7.333c1.473 0 2.667-1.194 2.667-2.666C10.667 3.194 9.473 2 8 2 6.527 2 5.333 3.194 5.333 4.667c0 1.472 1.194 2.666 2.667 2.666z"
            />
          </svg>
          {{ $t("patient.praticien") }}
        </div>

        <span class="font-EffraR text-lg text-black">
          {{ info.doctor.fullName }}
        </span>
      </div>

      <!-- Address -->
      <div class="flex flex-col">
        <div
          class="flex flex-row gap-x-1 items-center font-EffraR"
          style="color: #6d6d6d"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <g
              stroke="#767676"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              clip-path="url(#clip0)"
            >
              <path
                d="M14 6.667c0 4.666-6 8.666-6 8.666s-6-4-6-8.666c0-1.592.632-3.118 1.757-4.243C4.883 1.299 6.41.667 8 .667c1.591 0 3.117.632 4.243 1.757C13.368 3.549 14 5.075 14 6.667z"
              />
              <path
                d="M8 8.667c1.105 0 2-.896 2-2 0-1.105-.895-2-2-2s-2 .895-2 2c0 1.104.895 2 2 2z"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <path fill="#fff" d="M0 0H16V16H0z" />
              </clipPath>
            </defs>
          </svg>

          {{ $t("patient.address") }}
        </div>

        <span class="font-EffraR text-lg text-black">
          {{
            info.doctor &&
            info.doctor.landingPage &&
            info.doctor.landingPage.address.label
              ? info.doctor.landingPage.address.label
              : "--"
          }}
        </span>
      </div>
      <!-- Day -->
      <div class="flex flex-col">
        <div
          class="flex flex-row gap-x-1 items-center font-EffraR"
          style="color: #6d6d6d"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              stroke="#767676"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12.667 2.667H3.333C2.597 2.667 2 3.264 2 4v9.333c0 .737.597 1.334 1.333 1.334h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.736-.597-1.333-1.333-1.333zM10.667 1.333V4M5.333 1.333V4M2 6.667h12"
            />
          </svg>

          {{ $t("patient.date") }}
        </div>

        <span class="font-EffraR text-lg text-black">
          {{ _moment()(info.static.day).format("DD MMM YYYY") }}
        </span>
      </div>
      <!-- Hour -->
      <div class="flex flex-col">
        <div
          class="flex flex-row gap-x-1 items-center font-EffraR"
          style="color: #6d6d6d"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <path
              stroke="#767676"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 14.667c3.682 0 6.667-2.985 6.667-6.667S11.682 1.333 8 1.333 1.333 4.318 1.333 8 4.319 14.667 8 14.667z"
            />
            <path
              stroke="#767676"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8 4v4l2.667 1.333"
            />
          </svg>

          {{ $t("patient.heure") }}
        </div>

        <span class="font-EffraR text-lg text-black">
          {{ _moment()(info.static.day).format("HH:mm") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      info: JSON.parse(localStorage.getItem("booking_appointment"))
    };
  },
  methods: {
    _moment() {
      return moment;
    }
  }
};
</script>

<style></style>
